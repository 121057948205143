@import "master";
.subscribe-dialog-content {
  .subscribe-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
  }
}
.subscribe-button {
  cursor: pointer;
}
