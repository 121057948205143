@import "master";
.content-list {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    padding-top: 12px;

    .content-card {
        margin-bottom: 12px;
    }

    .content-list-header {
        color: $dark-grey;
        letter-spacing: 0.2px;
        padding-bottom: 5px;
        border-bottom: 1px solid rgba($grey, 0.5);
        margin-bottom: 12px;
    }
}