@import "master";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.pr-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  width: 100%;

  .pr-search-cards {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;

    .no-results {
      height: 291px;
    }

    .card-row {
      display: flex;
      max-width: 1082px;
      width: 100%;
      margin: 0 16px 48px;

      .xs & {
        margin: 0;
      }

      .card-container {
        padding: 10px;

        .card-actions {
          right: 14px;
          top: 14px;
        }

        &.selected {
          .xs & {
            background: transparent;
          }
        }

        .xs & {
          margin: 0;
          padding: 10px 18px;
        }

        .sm & {
          max-width: 100%;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .pr-pagination {
    width: 100%;
    margin-top: 40px;
  }
}
