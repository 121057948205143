@import "master";
.pr-checkbox {

  &.dark {
    label {
      color: $darker-grey;

      .checkmark {
        background-color: $grey;

        &::after {
          color: $white;
        }
      }
    }
  }

  &.outline {
    label {
      color: $darker-grey;

      .checkmark {
        border: 1px solid $darker-grey;
        background: none;
      }
    }
  }

  &.required {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > div {
      display: flex;
      align-items: center;
    }

    .required-icon {
      margin: 0 10px 0 0;
      color: $primary;
    }
  }

  label {
    position: relative;
    padding-left: 28px;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 12px;
    line-height: 19px;
    color: $white;
    margin-bottom: 0;

    &.error {
      .checkmark {
        border: 1px solid $primary;
      }
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      width: 100%;
      left: 0;
      z-index: 30;

      &:checked ~ .checkmark::after {
        display: block;
      }
    }

    .label {
      z-index: 32;
    }

    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      width: 16px;
      height: 16px;
      background-color: $lighter-grey;

      &::after {
        content: "x";
        color: $darker-grey;
        font-size: 12px;
        top: -3px;
        position: absolute;
        left: 5px;
      }
    }
  }

  .checkmark::after {
    display: none;
  }

  .error-label {
    font-size: 12px;
    color: $primary;
  }
}
