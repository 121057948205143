@font-face {
  font-family: "ToyotaText";
  src: url("../assets/fonts/ToyotaText-Regular.eot");
  src: url("../assets/fonts/ToyotaText-Regular.eot?#iefix") format("embedded-opentype"),
      url("../assets/fonts/ToyotaText-Regular.woff2") format("woff2"),
      url("../assets/fonts/ToyotaText-Regular.woff") format("woff"),
      url("../assets/fonts/ToyotaText-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ToyotaTextIt";
  src: url("../assets/fonts/ToyotaText-Italic.eot");
  src: url("../assets/fonts/ToyotaText-Italic.eot?#iefix") format("embedded-opentype"),
      url("../assets/fonts/ToyotaText-Italic.woff2") format("woff2"),
      url("../assets/fonts/ToyotaText-Italic.woff") format("woff"),
      url("../assets/fonts/ToyotaText-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

body {
    font-family: "ToyotaText" !important;
}
script{
    width: 0px;
    height: 0px;
    display: none;
  }
  
  // GLOBAL TYPOGRAPHY https://app.zeplin.io/project/5ac46a591677fa04a24f6c8b/screen/5afce6ace215dc29220f020f
  h1,
  h2,
  h3,
  h4,
  h5,
  p {
    color: #4a4a4a;
  }
  h1 {
    font-size: 40px;
    font-weight: normal;
    line-height: 1.22;
    letter-spacing: 0;

    &.light {
        font-size: 36px;
        line-height: normal;
        color: white;
    }
  }

  h2 {
      font-size: 24px;
      font-weight: normal;
      letter-spacing: 0.8px;

      &.light {
          color: white;
      }
  }
  
  h3 {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: 0.2px;
  }
  
  p {
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.2px;
    text-align: left;
    color: #666;
    margin-bottom: 25px;
  }
  
  @media (max-width: $screen-ss) {
    h1 {
      font-size: 28px;
      line-height: 1.14;
      letter-spacing: normal;
    }
  
    h3 {
      font-size: 24px;
      line-height: 1.33;
      letter-spacing: normal;
      font-weight: bold;
    }
  
    h4 {
      font-size: 18px;
      line-height: 1.33;
    }
  
    p {
      font-size: 14px;
      line-height: 1.71;
    }
  }
  
  
  @keyframes toRightFromLeft {
    30% {
        transform: translate(30%);
    }
    31% {
        opacity: 0;
        transform: translate(-50%);
    }
    100% {
        opacity: 1;
    }
  }
  
  @keyframes toLeftFromRight {
    30% {
        transform: translate(-30%);
    }
    31% {
        opacity: 0;
        transform: translate(50%);
    }
    100% {
        opacity: 1;
    }
  }
  
  @keyframes toTopFromBottom {
    30% {
        transform: translate(0, 100%);
    }
    31% {
        opacity: 0;
        transform: translate(0, -80%);
    }
    100% {
        opacity: 1;
    }
  }
  
  
  #app {
    .row {
      width: 100%;
      margin: 0;
    }
  }
  
a {
    font-size: 12px;
    color: $darker-grey;
    text-decoration: underline;

    &:hover, &:focus {
        color: $grey;
        text-decoration: underline;
    }
}

div[class^="MuiInput-root"] {
    font-family: "ToyotaText";

    ::placeholder {
        color: $dark-grey;
        line-height: 25px;
        font-size: 16px;
        letter-spacing: 0.2px;
        opacity: 1;
    }
}

.MuiTab-root-10 {
    font-family: "ToyotaText" !important;
}

button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
  