@import "master";
.pr-contact-card-list {
  display: flex;
  justify-content: center;
  margin: 60px 0;
  width: 100%;

  .xs & {
    margin: 24px 0;
  }

  .pr-contact-card-list-container {
    max-width: 1062px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 0 16px;
    justify-content: center;

    .xs &,
    .sm &,
    .md & {
      margin: 0;
    }

    .contact-card {
        margin: 12px;
    }
  }
}
