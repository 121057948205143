@import "master";
.pr-member-card {
    width: 25%;
    padding: 0 4px 6px 0;
    display: flex;
    flex-direction: column;

    .xs & {
        width: 50%;
        padding: 0 10px 15px 0;

        &:nth-child(even) {
            padding: 0 0 15px 5px;
        }

        &:nth-child(odd) {
            padding: 0 5px 15px 0;
        }
    }

    .pr-member-card-image {
        width: 100%;
        background-size: cover;
        background-position: center;

        &.bg-img {
            padding-top: 100%;
        }

        img {
            width: 100%;
        }
    }

    .pr-member-card-copy {
        background: linear-gradient(rgba($light-grey, 0.8), rgba($lighter-grey, 0.5));
        padding: 18px;
        text-align: center;
        height: 100%;

        .pr-member-card-position {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: $grey;
        }
    }
}

.pr-member-card-list {
    display: flex;
    justify-content: center;
    margin: 60px 0;
    width: 100%;

    .xs & {
        margin: 24px 0;
    }

    .pr-member-card-list-container {
        max-width: 1062px;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin: 0 16px;

        .xs &, .sm &, .md & {
            margin: 0;
        }
    }
}