@import "master";
.file-upload-container {
  .preview-container {
    .image-preview {
      width: 100px;
      height: 100px;
      overflow: hidden;
      margin: 0 10px;
      position: relative;

      .delete-icon {
        position: absolute;
        top: -2px;
        height: 15px;
        width: 15px;
        right: 0px;

        .PRIcon {
          width: 15px;
          height: 15px;
          background-color: $black;
          padding: 3px;
          border-radius: 50%;
        }
      }

      img {
        width: 100%;
      }
    }

    .file-preview {
      display: flex;

      .delete-icon {
        .PRIcon {
          width: 15px;
          height: 15px;
          background-color: $black;
          padding: 3px;
          border-radius: 50%;
        }
      }
    }
  }
}

.pr-dialog {
  &.file-selection-dialog {
    .dialog-content {
      .dialog-content-container {
        max-width: 100%;
        margin: 0;

        .button-container {
          display: flex;
          justify-content: center;
          margin: 10px 10px 0;

          .submit-btn {
            margin-right: 20px;
          }
        }
      }
    }
  }
}
