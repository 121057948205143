.pr-dropdown {
  display: flex;
  align-items: center;
  position: relative; }
  .pr-dropdown:not(.required) {
    padding-left: 17px; }
  .pr-dropdown.required {
    padding: 0; }
  .pr-dropdown .required {
    margin: 25px 9px 0 0;
    color: #d0021b; }
  .pr-dropdown .dropdown-label {
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    top: -4px;
    font-family: "ToyotaText"; }
    .pr-dropdown .dropdown-label.focused {
      color: #fff; }
  .pr-dropdown .dropdown-form-control {
    margin-left: 30px;
    min-width: 100px; }
    .pr-dropdown .dropdown-form-control .input-underline {
      color: #fff;
      letter-spacing: 0.2px;
      font-size: 16px; }
      .pr-dropdown .dropdown-form-control .input-underline::before {
        border-bottom: 2px solid rgba(155, 155, 155, 0.8); }
      .pr-dropdown .dropdown-form-control .input-underline::after {
        border-bottom: 2px solid rgba(155, 155, 155, 0.8); }
      .pr-dropdown .dropdown-form-control .input-underline .dropdown-root {
        min-width: 65px;
        z-index: 10; }
        .pr-dropdown .dropdown-form-control .input-underline .dropdown-root .dropdown-select {
          text-align: right;
          padding-right: 28px; }
    .pr-dropdown .dropdown-form-control .dropdown-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: calc(50% - 8px); }
  .pr-dropdown.dark .dropdown-label {
    color: #666666; }
    .pr-dropdown.dark .dropdown-label.focused {
      color: #666666; }
  .pr-dropdown.dark .dropdown-icon {
    fill: #666666; }
  .pr-dropdown.dark .dropdown-root {
    color: #666666; }
  .pr-dropdown .error-text {
    position: absolute;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #d0021b;
    right: 30px;
    bottom: 8px; }

[id*="menu-filter"] [class*="MuiListItem-root"] {
  justify-content: center;
  padding: 12px 40px; }
