@import "master";
.media-library-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .media-library-inner-container {
        display: flex;
        width: 100%;
        max-width: 1062px;

        button {
            background-color: $light-grey;
            margin-top: 15px;
            width: 187px;
        }

        .file-upload-container {
            width: 100%;

            .dropzone-container {
                display: flex;

                button {
                    background-color: $light-grey;
                    padding: 10px;
                }

                .preview-container {
                    display: flex;
                    width: 100%;
                    flex-wrap: wrap;

                    .image-preview {
                        width: 20%;
                        height: auto;
                        margin: 10px;
                    }
                }
            }
        }
    }
}