@import "master";
.pr-dropdown {
  display: flex;
  align-items: center;
  position: relative;

  &:not(.required) {
    padding-left: 17px;
  }

  &.required {
    padding: 0;
  }

  .required {
    margin: 25px 9px 0 0;
    color: $primary;
  }

  .dropdown-label {
    color: $white;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    top: -4px;
    font-family: "ToyotaText";
    &.focused {
      color: $white;
    }
  }

  .dropdown-form-control {
    margin-left: 30px;
    min-width: 100px;

    .input-underline {
      color: $white;
      letter-spacing: 0.2px;
      font-size: 16px;

      &::before {
        border-bottom: 2px solid rgba($grey, 0.8);
      }

      &::after {
        border-bottom: 2px solid rgba($grey, 0.8);
      }
      .dropdown-root {
        min-width: 65px;
        z-index: 10;

        .dropdown-select {
          text-align: right;
          padding-right: 28px;
        }
      }
    }

    .dropdown-icon {
      width: 20px;
      height: 20px;
      position: absolute;
      right: 0;
      top: calc(50% - 8px);
    }
  }

  &.dark {
    .dropdown-label {
      color: $dark-grey;
      &.focused {
        color: $dark-grey;
      }
    }
    .dropdown-icon {
      fill: $dark-grey;
    }
    .dropdown-root {
      color: $dark-grey;
    }
  }

  .error-text {
    position: absolute;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $primary;
    right: 30px;
    bottom: 8px;
  }
}

[id*="menu-filter"] {
  [class*="MuiListItem-root"] {
    justify-content: center;
    padding: 12px 40px;
  }
}
