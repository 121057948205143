@import "master";
.pr-carousel {
    max-width: 1296px;
    width: 100%;
    margin: auto;
    display: flex;

    &.xs {
        position: relative;
        .pr-carousel-container {
            padding: 0;
            .alice-carousel {
                padding: 0;
            }
        }

        .pr-carousel-control {
            position: absolute;
            top: calc(50% - 50px);
            padding: 0;
            width: 60px;
            z-index: 1;

            .PRIcon {
                fill: $white;
                width: 40px;
                height: 40px;
            }

            &.next {
                right: 0;
                justify-content: flex-end;
            }

            &.prev {
                justify-content: flex-start;
            }
        }
    }

    .pr-carousel-control, .pr-carousel-container {
        display: flex;
        align-items: center;
    }

    .pr-carousel-control {
        cursor: pointer;
        &:hover {
            .PRIcon {
                fill: $grey;
            }
        }
        
        .PRIcon {
            fill: $darker-grey;
            width: 70px;
            height: 70px;
        }

        &.prev {
            justify-content: flex-end;
            .PRIcon {
                -moz-transform: scaleX(-1);
                -o-transform: scaleX(-1);
                -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
            }
            
        }
    }

    .pr-carousel-container {
        width:100%;

        .alice-carousel {
            padding: 0px 0 30px;

            .alice-carousel__wrapper {
                border: none;
                
                .alice-carousel__stage-item {
                    // max-width: 333.328px;
                }
            }
        }

        .pr-slide-container {
            display: flex;
            justify-content: center;
        
            .pr-slide {
                padding: 0 19px;
                cursor: pointer;
                // max-width: 282px;
                width: 100%;

                    &:hover, &:focus {
                        background-color: rgba($light-grey, 0.5);
                    }

                img {
                    width: 100%;
                    margin: 20px 0 0px;

                    .xs & {
                        margin-top: 0;
                    }
                }

                .pr-slide-text {
                    display: flex;
                    justify-content: center;
                    h3 {
                        text-decoration: underline;
                        &:hover {
                            color: $grey;
                        }
                    }
                }
            }
        }
    }

    
}