@import "master";
.pr-footer {
  background: $darker-grey;
  width: 100%;
  height: 343px;
  color: $white;
  display: flex;
  justify-content: center;
  padding: 43px 0 53px;

  &.xs {
    height: auto;
    min-height: 640px;
    padding: 33px 0 20px;

    .pr-footer-container {
      flex-direction: column;
      justify-content: flex-start;

      .footer-content {
        margin: 0;
        width: 100%;
        height: 75%;
      }

      .footer-logo {
        margin: 8px 16px 22px;
        border: none;

        img {
          width: 71px;
        }
      }

      .links-container,
      .social-container,
      .subscribe-container {
        padding: 0;
        margin: 0 16px;

        &:first-child {
          padding: 0;
          margin: 0 16px 40px;
        }
      }

      .footer-title {
        margin-bottom: 20px;
        font-size: 18px;
      }

      .links {
        a {
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.2px;
        }
      }

      .icons {
        .PRIcon {
          width: 38px;
          height: 37px;
          margin-right: 10px;
        }
      }

      .terms {
        padding: 0;
        margin: 0 16px;
        font-size: 16px;
        line-height: 25px;
        a {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }
  }

  .pr-footer-container {
    max-width: 1062px;
    display: flex;
    width: 100%;

    .footer-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 10px;
    }

    .footer-logo {
      border-right: 1px solid rgba($grey, 0.2);
      padding-right: 15px;
      margin-top: 7px;
      margin-left: 10px;
    }

    .links-container,
    .social-container,
    .subscribe-container {
      padding: 0 10px;
      min-width: 220px;

      &:first-child {
        padding-left: 8px;
      }
    }

    .footer-title {
      color: $white;
      border-bottom: solid 1px $white;
      line-height: 36px;
      margin-bottom: 22px;
    }

    .links {
      .container {
        padding-left: 0;
      }

      a {
        color: $white;
        line-height: 20px;
        letter-spacing: 0.1px;
        display: block;

        &:hover,
        &:focus {
          color: $light-grey;
        }
      }
    }

    .icons {
      .PRIcon {
        margin-right: 9px;
        cursor: pointer;
        width: 23px;
        height: 23px;

        &.instagram {
          margin-right: 16px;

          .xs & {
            margin-right: 34px;
          }
        }

        &:hover,
        &:focus {
          fill: $light-grey;
        }
      }
    }

    .terms {
      font-size: 12px;
      padding-left: 8px;

      .container {
        display: inline;
        padding: 0px;
        margin: 0px;
        a {
          color: $white;
          line-height: 20px;
          letter-spacing: 0.1px;
          display: inline;
          &:hover,
          &:focus {
            color: $light-grey;
          }

          .xs & {
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.2px;
          }
        }
        .divider {
          display: inline;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}
