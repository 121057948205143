@import "master";
.pr-download {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .pr-download-container {
    width: 100%;
    max-width: 1062px;
    display: flex;
    margin: 0 16px;

    .xs & {
      flex-direction: column;
    }

    .download-content-container {
      padding-right: 10px;
      width: 75%;

      .xs & {
        width: 100%;
        padding: 0;
      }

      table {
        width: 100%;
        tr {
          font-size: 12px;

          th,
          td {
            padding: 30px 10px;
            font-weight: normal;
            text-align: center;
          }

          &.header {
            .file {
              text-align: left;
            }

            button {
              text-decoration: underline;

              &:hover,
              &:focus {
                text-decoration: none;
                outline: none;
              }
            }
          }

          &:not(.header) {
            td {
              padding: 8px 10px;

              > div {
                min-height: 44px;
                display: flex;
                align-items: center;
                justify-content: center;
                max-width: 210px;

                .PRIcon {
                  width: 15px;
                  height: 18px;
                  fill: $primary;
                }

                &.delete-container {
                  cursor: pointer;
                  .PRIcon {
                    margin-right: 14px;
                  }
                }

                &.file-name {
                  text-align: left;
                  justify-content: flex-start;

                  .PRIcon {
                    width: 20px;
                    height: 23px;
                    margin-right: 14px;
                    fill: $lighter-black;
                    path {
                      fill: $lighter-black;
                    }
                  }

                  span {
                    max-width: 172px;
                  }
                }
              }
            }

            &:nth-child(even) {
              background-color: $lighter-grey;
            }
          }
        }
      }

      .download-card-container {
        display: inline-block;
        width: 100%;
        background-color: $lighter-grey;
        margin: 10px 0;
        padding: 16px;
        color: $darker-grey;

        .download-card-content {
          width: 100%;

          .generic-detail {
            margin-left: 32px;
          }

          .generic-detail-container,
          .action-container {
            display: flex;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.1px;
            width: 100%;
          }

          .generic-detail-container {
            margin-top: 10px;
            .labels-container {
              margin-right: 5px;
            }
          }

          .action-container {
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            > div {
              display: flex;
              align-items: center;
            }
            .PRIcon {
              width: 20px;
              height: 23px;
              fill: $lighter-black;
              margin-right: 11px;
            }

            .delete-container {
              cursor: pointer;
              .PRIcon {
                width: 15px;
                height: 15px;
                fill: $primary;
                margin: 0;
              }
            }

            .pr-checkbox {
              margin: 0px 4px 6px 0;
            }
          }
        }
      }

      .pr-checkbox {
        margin: 0;
        input {
          position: static;
        }
        .checkmark {
          background-color: $grey;

          &::after {
            color: $white;
          }
        }
      }
    }

    .download-action-container {
      padding-left: 10px;
      width: 25%;
      margin-top: 77px;

      .xs & {
        width: 100%;
        padding: 0;
        margin-top: 10px;
      }

      .sm & {
        margin-top: 10px;
      }

      .download-action {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        min-height: 60px;
        background-color: $lighter-grey;
        border-bottom: 1px solid rgba($grey, 0.2);
        padding: 10px 20px;
        color: $dark-grey;
        cursor: pointer;

        &:hover {
          background-color: rgba($light-grey, 0.6);
        }

        &:last-child {
          border-bottom: none;
        }

        &.disabled {
          cursor: default;
          color: $grey;

          .PRIcon {
            fill: $grey;
          }

          &:hover {
            background-color: $lighter-grey;
          }
        }

        .icon-container {
          width: 25px;
          margin-right: 18px;
          display: flex;
          justify-content: center;
        }
      }

      .PRIcon {
        width: 25px;
        fill: $lighter-black;

        &.close-icon {
          width: 18px;
        }
      }
    }
  }
}

.download-dialog-header {
  display: flex;
  align-items: center;
  .PRIcon {
    width: 25px;
    height: 35px;
    fill: $lighter-black;
    opacity: 0.5;
    margin-right: 12px;
  }
}
