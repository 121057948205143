@import "master";
.media-library-container {
  .search-results-heading {
    max-width: 1098px;
    width: 100%;
    margin: 39px 0 0;
    padding: 0 18px;
    display: flex;
    flex-direction: row;
    align-items: start;
    .PRIcon {
      height: 24px;
      width: 24px;
      fill: $grey;
      margin-right: 10px;
      position: static;
    }
    h2 {
      padding-bottom: 10px;
      border-bottom: solid 1px $light-grey;
      width: 100%;
    }
  }
  .view-all {
    margin-bottom: 39px;
  }
}
