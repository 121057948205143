@import "master";
.pr-tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    width: 100%;
    max-width: 1280px;

    .pr-tabs-header-container {
        display: flex;
        align-items: center;
        max-width: 1062px;
        width: 100%;
        margin: 30px 0;

        .xs & {
            padding: 0;
        }

        .tab-prefix {
            font-size: 18px;
            letter-spacing: 0.2px;
            color: $darker-grey;
            padding-right: 30px;

            .xs & {
                padding-right: 17px;
            }
        }

        .pr-tabs-header-list {
            display: flex;

            .pr-tab-label-container {
                font-size: 18px;
                letter-spacing: 0.2px;
                padding: 10px 30px 8px;
                border-right: solid 1px $light-grey;
                color: $darker-grey;
                cursor: pointer;

                .xs & {
                    padding: 0px 16px 4px;
                }

                .pr-tab-label {
                    text-align: center;
                }

                &:last-child {
                    border-right: none;
                }

                &:first-child {
                    padding-left: 0;
                }

                &:hover, &:focus {
                    outline: 0;
                    .pr-tab-label {
                        padding-bottom: 1px;
                        border-bottom: 1px solid $darker-grey;
                    }
                }

                &.active {
                    .pr-tab-label {
                        color: $primary;
                        padding-bottom: 1px;
                        border-bottom: 1px solid $primary;
                    }
                }
            }
        }
    }



    .pr-tabs-content {
        width: 100%;
        position: relative;

        >div {
            display: block;
            min-width: 100%;
            max-width: 100%;
            position: absolute;
            visibility: hidden;
            top: 0;

            &.active {
                visibility: visible;
                position: relative;
            }
        }

        .xs & {
            padding: 10px 0 25px;
        }

        .pr-tab {
            display: flex;
            width: 100%;
            justify-content: center;

            >div {
                width: 100%;
                >div {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}