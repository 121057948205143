@import "master";
.select-slider {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    // overflow-x: auto;

    >div {
        width: 100%;
        // overflow-x: auto;
        // display: flex;
    }

    .select-slider-container {
        position: relative;
        width: 100%;
        height: auto;
        overflow: hidden;

        .select-slider-inner-container {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0px;
            padding: 0px;
            white-space: nowrap;
        }

        .slider-item {
            // min-width: 33.33%;
            text-align: center;
            padding: 0 16px;
            display: inline-block;
            position: relative;
            width: 33.33%;

            &.selected {
                color: $white;
            }
        }
    }
}