@import "master";
.contact-card {
  width: 100%;
  max-width: 303px;
  background: linear-gradient(rgba($grey, 0.5), rgba($lighter-grey, 0.5));

  .contact-card-header {
    display: flex;
    justify-content: center;
    background-color: $primary;
    padding: 12px;
    font-size: 12px;
    letter-spacing: 0.1px;
    font-weight: bold;
    color: $white;
  }

  .contact-card-content {
    padding: 30px 0 0;

    .contact-card-image-container {
      display: flex;
      justify-content: center;

      .contact-card-image {
        width: 148px;
        height: 148px;
        background-size: cover;
        background-position: center;
        border-radius: 50%;

        img {
          width: 148px;
          height: 148px;
          border-radius: 50%;
          object-fit: cover;
          font-family: "object-fit: cover;";
        }
      }
    }

    .contact-card-section {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 17px 0 21px;
      border-bottom: 1px solid rgba($grey, 0.2);

      &:last-child {
        border-bottom: none;
      }

      &.contact-card-name-container {
        padding: 8px 0 16px;

        .contact-card-name {
          font-size: 16px;
          line-height: 25px;
          font-weight: bold;
          color: $dark-grey;
        }

        .contact-card-description {
          font-size: 12px;
          line-height: 20px;
          color: $darker-grey;
          padding: 8px;
        }
      }

      &.contact-card-email-container {
        .contact-card-email-icon {
          width: 30px;
          height: 30px;
          fill: $lighter-black;
        }

        .contact-card-email {
          font-size: 12px;
          line-height: 20px;
          text-decoration: underline;
          color: $darker-grey;
        }
      }

      &.contact-card-phone-container {
        .contact-card-phone-icon {
          width: 30px;
          height: 30px;
          fill: $lighter-black;
        }

        .contact-card-phone {
          font-size: 12px;
          line-height: 20px;
          color: $darker-grey;
          margin-top: 4px;
        }
      }
    }
  }

  .xs &,
  .sm & {
    max-width: unset;
    margin-bottom: 12px;
  }
}
