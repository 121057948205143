@import "master";
.list-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    margin-bottom: 10px;
    border-bottom: rgba($grey, 0.5) 1px solid;

    .list-header-label {
        color: $dark-grey;
    }
}