@import "master";
.pr-image-card {
    img {
        width: 100%;
        height: auto;
    }

    .pr-image-description-container {
        background: linear-gradient(rgba($light-grey, 0.8), rgba($darker-grey, 0.5));
        padding: 27px;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $darker-grey;
    }
}