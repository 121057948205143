@import "master";
.pr-document-card {
    background-color: rgba($light-grey, 0.36);
    padding: 18px;
    display: flex;
    margin: 15px 0;

    .pr-document-icon {
        padding: 6px;

        .PRIcon {
            width: 32px;
            height: 36px;
            fill: $lighter-black;
        }
    }

    .pr-document-content {
        padding: 3px 0 3px 18px;
        color: $dark-grey;

        .document-card-actions {
            display: flex;
            margin-top: 7px;

            .document-card-action-item {
                width: 40px;
                height: 40px;
                background-color: $white;
                display: flex;
                justify-content: center;
                align-items: center;

                .PRIcon {
                    &.plus {
                        width: 15px;
                        height: 15px;
                    }

                    &.download {
                        width: 21px;
                        height: 21px;
                        fill: $lighter-black;
                        margin-bottom: 4px;
                    }
                }

                &:first-child {
                    border-right: 1px solid rgba($grey, 0.2);
                }
            }
        }
    }
}