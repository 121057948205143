@import "master";
.simple-image-upload {
  margin-top: 8px;

  &:not(.required) {
    padding-left: 17px;
  }

  &.required {
    padding: 0;
    .file-upload {
      padding-left: 17px;
    }
  }

  &.error {
    .file-upload {
      .dropzone-control {
        border: 1px solid $primary;
      }
    }
  }

  .required {
    margin: 25px 9px 0 0;
    vertical-align: middle;
    color: $primary;
  }

  .error-text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $primary;
    float: right;
    padding-top: 4px;
  }

  .file-upload {
    width: 100%;
    background: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .dropzone-control {
      position: relative;
      width: 100%;
      height: 100%;
      border: 1px dashed;

      .file-upload-dropzone {
        height: 252px;
        width: 100%;
        padding: 10px;
        > div {
          height: 232px;
          overflow: hidden;
          width: 100%;
          .image,
          .video {
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .file-upload-actions {
      position: absolute;
      display: flex;
      border-left: none;
      width: 100%;
      justify-content: space-evenly;
    }
  }

  .file-preview {
    width: 100%;
    background: none;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .image-preview,
    .video-preview {
      width: 100%;
      height: 254px;
      overflow: hidden;
    }
  }
}
