@import "master";
.contact-container {
  &:not(.required) {
    padding-left: 17px;
  }

  &.required {
    padding: 0;
    .autocomplete-container {
      padding-left: 17px;
    }
  }

  .required {
    margin: 25px 9px 0 0;
    vertical-align: middle;
    color: $primary;
  }

  .error-text {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $primary;
    float: right;
    padding-top: 4px;
  }

  .autocomplete-container {
    display: flex;

    .PRIcon {
      width: 20px;
      height: 20px;
      fill: $black;
      margin-left: 10px;
    }

    .input-underline {
      &::before {
        border-bottom-width: 1px;
      }
      &::after {
        border-bottom-width: 1px;
      }
    }
    .input-error {
      &::after {
        border-bottom-color: #f44336;
      }
    }
  }

  .contact-form {
    .simple-image-upload {
      width: 282px;
      .file-upload {
        .dropzone-control {
          height: 282px;
          .file-upload-dropzone {
            height: 282px;
            > div {
              height: 262px;
            }
          }
        }
        .file-upload-actions {
          flex-direction: column;
          width: auto;
          height: 80%;
        }
      }
    }
  }
}
