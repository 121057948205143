@import "master";
.prButton, .prButton:not([href]):not([tabindex]) {
    border-radius: 0;
    font-size: 16px;
    line-height: 25px;
    display: flex;
    border: none;
    align-items: center;
    cursor: pointer;
    text-decoration: none;

    background-color: $primary;
    color: $white;
    padding: 6px 8px 6px 14px;

    .PRIcon {
        margin: -2px 0 0 8px;
        width: 24px;
        height: 24px;
    }

    &:hover, &:focus {
        background-color: $dark-primary;
        color: $white;
    }

    &.prButton-header {
        background-color: $white;
        padding: 16px 22px;
        color: $dark-grey;

        .PRIcon {
            fill: $dark-grey;
        }

        &:hover, &:focus {
            background-color: $lighter-grey;
        }
    }

    &.prButton-secondary {
        background-color: $white;
        color: $primary;

        .PRIcon {
            fill: $primary;
        }

        &:hover, &:focus {
            background-color: $lighter-grey;
        }
    }

    &.prButton-tertiary {
        font-family: "ToyotaTextIt";
        font-size: 12px;
        letter-spacing: 0;
        background-color: transparent;
        color: $primary;
        padding: 0;

        .PRIcon {
            fill: $primary;
            width: 14px;
            height: 14px;
        }

        &:hover, &:focus {
            color: $dark-grey;

            .PRIcon {
                fill: $dark-grey;
            }
        }
    }
}