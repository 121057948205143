@import "master";
.admin-panel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .panel-header,
  .panel-footer {
    width: 100%;
    background-color: $lighter-grey;
    padding: 20px;

    button {
      background-color: $light-grey;
      margin-right: 10px;
      padding: 10px;

      &:hover {
        background-color: $grey;
      }
    }

    .publish-buttons {
      display: flex;
      .publish-status {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 10px;
      }
    }
  }

  .admin-panel-inner-container {
    max-width: 1062px;
    width: 100%;
    margin-bottom: 20px;

    .input-container {
      margin-top: 20px;

      &.custom-input {
        padding-left: 17px;
      }

      &.file-selection {
        &:not(.required) {
          padding-left: 17px;
        }

        &.required {
          padding: 0;
          .file-upload-container {
            padding-left: 17px;
          }
          .preview-container {
            padding-left: 17px;
            &.sortable {
              padding-left: 0px;
            }
          }
        }

        .preview-container {
          display: flex;
          flex-wrap: wrap;
          padding-top: 20px;
          .image-preview {
            width: 150px;
            height: 87px;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            border: 1px $grey solid;
            margin: 20px;
            margin-left: 0;
          }
          &.sortable {
            .press-release-preview {
              display: flex;
              padding: 20px;
              padding-left: 17px;
              cursor: grab;
              &.dragged {
                background: $lighter-grey;
                cursor: grabbing;
              }
              > div {
                margin-right: 20px;
              }
              .press-release-order {
                h3 {
                  margin-top: 29px;
                }
              }
              .press-release-image {
                width: 150px;
                height: 87px;
                overflow: hidden;
                background-size: cover;
                background-position: center;
                border: 1px $grey solid;
                flex-shrink: 0;
              }
              .press-release-content {
                .press-release-description {
                  font-size: 12px;
                  line-height: 20px;
                  letter-spacing: 0.1px;
                }
              }
              .press-release-actions {
                margin-left: auto;
              }
            }
          }
        }

        .required {
          margin: 25px 9px 0 0;
          vertical-align: middle;
          color: $primary;
        }

        .error-text {
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.1px;
          color: $primary;
          float: right;
          padding-top: 4px;
        }
      }

      &.thumbnail {
        .simple-image-upload {
          width: 50%;
          .file-upload {
            .dropzone-control {
              height: 300px;
              .file-upload-dropzone {
                height: 300px;
                > div {
                  height: 280px;
                }
              }
            }
          }
        }
      }

      &.banner {
        .simple-image-upload {
          .file-upload {
            .dropzone-control {
              height: 400px;
              .file-upload-dropzone {
                height: 400px;
                > div {
                  height: 380px;
                }
              }
            }
          }
        }
      }

      &.create-press-release {
        padding-left: 17px;
        display: flex;
      }

      .pr-checkbox {
        padding-left: 17px;
      }

      .pr-dropdown {
        .dropdown-form-control {
          min-width: 300px;
          .input-underline {
            &::before {
              border-bottom-width: 1px;
            }
            &::after {
              border-bottom-width: 1px;
            }
          }
          .input-error {
            &::after {
              border-bottom-color: #f44336;
            }
          }
        }
      }

      label {
        font-size: 12px;
        line-height: 20px;
      }

      .dropzone-container {
        display: flex;

        .preview-container {
          display: flex;
          flex-wrap: wrap;

          .image-container {
            width: 100px;
            height: 100px;
            margin: 10px;
            border: 1px solid;
            position: relative;

            .delete-icon {
              position: absolute;
              top: -14px;
              right: -8px;

              .PRIcon {
                width: 20px;
                height: 20px;
                background-color: $black;
                padding: 5px;
                border-radius: 50%;
              }
            }

            img {
              width: 100%;
              height: auto;
              max-height: 100%;
            }
          }
        }

        .dropzone {
          min-width: 200px;
          min-height: 200px;
          max-width: 200px;
        }

        .video-preview-container {
          display: flex;
          flex-wrap: wrap;

          .video-container {
            display: flex;
            padding: 0 10px;

            span {
              padding-top: 3px;
            }

            .delete-icon {
              margin-left: 10px;
              .PRIcon {
                width: 20px;
                height: 20px;
                background-color: $black;
                padding: 5px;
                border-radius: 50%;
              }
            }
          }
        }
      }

      .pr-date {
        .datepicker-root {
          .input-label-root {
            color: $darker-grey;
          }
          .input-root {
            .input-input {
              color: $darker-grey;
            }
            .calendar-icon {
              #calendar-icon-g {
                fill: $darker-grey;
              }
            }
          }
        }
      }
    }
  }
}
