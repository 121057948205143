@import "master";
.pr-textfield {
  display: flex;
  align-items: center;
  position: relative;

  &:not(.required) {
    padding-left: 17px;
  }

  &.required {
    padding: 0;
  }

  &.light {
    .textfield-root {
      label {
        color: $white;
      }

      .input-root {
        &::before {
          border-bottom: 2px solid rgba($grey, 0.8);
        }

        .input-input {
          color: $white;

          &:focus {
            color: $white;
          }
        }

        &.input-underline {
          &::after {
            border-bottom: 2px solid $black;
          }
        }
      }
    }
  }

  .textfield-root {
    width: 100%;

    label {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1px;
      color: $darker-grey;
      top: -4px;
      font-family: "ToyotaText";

      [class*="MuiFormLabel-asterisk"] {
        display: none;
      }

      .xs & {
        font-size: 16px;
      }
    }

    .input-root {
      &::before {
        border-bottom-color: $grey;
      }

      &.input-underline {
        &.input-error {
          &::after {
            border-bottom-width: 1px;
          }
        }
      }
    }
  }

  .required {
    margin: 25px 9px 0 0;
    color: $primary;
  }

  .error-text {
    position: absolute;
    bottom: 15px;
    right: 0;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: $primary;
  }
}
