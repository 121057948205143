@import "master";
.card-container {
  max-width: 216px;
  width: 100%;
  position: relative;

  &.selected {
    background-color: rgba($light-grey, 0.3);
  }

  &.clickable {
    cursor: pointer;
  }

  a {
    text-decoration: none;
  }

  &.xs {
    max-width: 100%;
    padding: 0;

    .card-image-container {
      max-height: 200px;

      .image {
        height: 190px;
      }
    }

    .card-date {
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.2px;
    }

    .card-copy-container {
      .card-title {
        font-size: 24px;
        letter-spacing: 0.8px;
      }
      .card-copy {
        display: none;
      }
    }
  }

  .card-image-container {
    position: relative;
    max-height: 109px;
    overflow: hidden;

    .image {
      width: 100%;
      height: 109px;
      background-size: cover;
      background-position: center;
    }

    .card-video-icon {
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);

      .PRIcon {
        width: 40px;
        height: 40px;
      }
    }
  }

  .card-date {
    display: inline-block;
    background-color: $grey;
    font-size: 12px;
    line-height: 20px;
    font-weight: bold;
    padding: 4px 9px;
    color: $white;
    letter-spacing: 0.3px;
  }

  .card-copy-container {
    margin-top: 13px;

    .card-copy {
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.1px;
      margin-bottom: 12px;
    }

    .card-cta {
      display: flex;
      align-items: center;
    }

    .draft-label {
      font-size: 12px;
      letter-spacing: 0;
      background-color: transparent;
      color: $primary;
      padding: 0;
    }
  }

  img {
    width: 100%;
  }
}
