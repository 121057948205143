@import "master";
.press-kit {
    transform: translateY(-103px);

    &.includes-flag {
        transform: translateY(-127px);
        .xs &, .sm & {
            transform: translateY(-24px);
        }

        .press-kit-flag-container {
            display: flex;
            justify-content: center;

            .press-kit-flag {
                max-width: 1062px;
                width: 100%;

                span {
                    background-color: $primary;
                    height: 100%;
                    padding: 3px 12px;
                    font-size: 12px;
                    color: $white;
                }
            }
        }
    }

    .xs &, .sm & {
        transform: none;
    }

    .header-container {
        min-height: 103px;
        display: flex;
        justify-content: center;
        width: 100%;
        background-color: rgba($darker-grey, 0.7);

        .header-inner-container {
            max-width: 1062px;
            width: 100%;
            display: flex;
            align-items: center;
            margin: 18px;
            justify-content: space-between;

            .xs &, .sm & {
                flex-direction: column;
                margin: 0;
                align-items: flex-start;
            }

            .title-filter {
                display: flex;

                .xs &, .sm & {
                    flex-direction: column;
                    width: 100%;
                }

                .filters {
                    display: flex;
                    align-items: center;

                    .xs &, .sm & {
                        flex-direction: column;
                        background: linear-gradient(rgba($darker-grey, 0.25), rgba($darker-grey, 0.3));

                        .filter {
                            height: 62px;
                            width: 100%;
                            // padding: 0 16px;
                        }
                    }
                }
            }

            h1 {
                margin: 0;
                .xs &, .sm & {
                    font-size: 24px;
                    letter-spacing: 0.8px;
                    margin: 16px;
                }
            }

            .complex-tab-list {
                display: ruby;

                .xs &, .sm & {
                    background-color: $lighter-grey;
                    width: 100%;
                    height: 56px;
                    align-items: center;
                }

                .complex-tab {
                    padding: 5px 16px;
                    border-right: 1px solid rgba($light-grey, 0.2);
                    color: $lighter-grey;
                    cursor: pointer;

                    .xs &, .sm & {
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 100%;
                        border-right-color: $white;
                        color: $dark-grey;
                        text-align: center;
                    }

                    &.active {
                        color: $grey;

                        .xs &, .sm & {
                            background-color: $grey;
                            color: $white;
                        }
                    }

                    &:hover {
                        color: $grey;
                    }

                    &:last-child {
                        border-right: none;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .pr-tabs-content {
        width: 100%;

        .xs & {
            padding: 10px 0 25px;
        }

        .pr-tab {
            display: flex;
            width: 100%;
            justify-content: center;

            >div {
                width: 100%;
            }
        }
    }

    .complex-tabs-flag-container {
        display: flex;
        justify-content: center;

        .complex-tabs-flag {
            max-width: 1062px;
            width: 100%;

            span {
                color: $white;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.1px;
                background-color: $primary;
                padding: 3px 12px;
            }
        }
    }
}