@import "master";
.card-actions {
  position: absolute;
  width: auto;
  z-index: 10;
  right: 4px;
  background-color: $white;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .card-action-icon {
    padding: 2px;
    height: 28px;
    width: 28px;
    background: white;
    color: $grey;
    cursor: pointer;

    &:not(:last-child) {
      border-right: 1px solid $light-grey;
    }

    .PRIcon {
      fill: $grey;
      font-size: 24px;
      top: 0;
      left: 0;
    }
  }

  .card-action-item {
    display: flex;
    width: 28px;
    height: 28px;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;

    &.checkbox {
      padding: 0;
    }

    .pr-checkbox {
      label {
        .checkmark {
          top: -8.5px;
          left: 6.5px;
        }
      }
    }

    &:not(:last-child) {
      border-right: 1px solid $light-grey;
    }

    .PRIcon {
      width: 11px;
      height: 11px;
      fill: $lighter-black;

      &.download {
        width: 16px;
        height: 16px;
        margin-bottom: 4px;
      }

      &.plus {
        width: 11px;
        height: 11px;
        margin-bottom: 3px;
      }

      &.edit {
        height: 100%;
      }
    }
  }

  .xs {
    width: 78px;
    right: 6px;
    top: 6px;

    .card-action-item {
      height: 40px;

      .PRIcon {
        &.plus {
          width: 18px;
          height: 18px;
          margin-bottom: 4px;
        }

        &.link-share {
          width: 22px;
          height: 24px;
          margin-bottom: 4px;
        }
      }
    }
  }
}
