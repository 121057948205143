@import "master";
.pr-image-slider {
  width: 100%;

  &.xs {
    .carousel-text-container {
      position: relative;
      transform: translateY(-27px);
      padding-bottom: 0;
      margin-bottom: -27px;

      .carousel-text {
        .carousel-text-inner {
          padding: 0;

          .carousel-text-date {
            margin-left: 17px;
          }

          .carousel-text-copy {
            padding: 22px 17px 18px;
            background-color: rgba($darker-grey, 0.7);
          }
        }
      }
    }

    .carousel-indicators {
      bottom: 184px;

      li {
        width: 9px;
        height: 9px;
      }
    }
  }
  .carousel-text-container {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 12%;
    z-index: 10;
    padding-bottom: 20px;
    color: $white;
    text-align: center;
    width: 100%;

    .carousel-text {
      max-width: 1296px;
      width: 100%;

      .carousel-text-inner {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        text-align: left;

        .carousel-text-copy {
          background-color: rgba($darker-grey, 0.9);
          padding: 26px;
          font-size: 16px;
          line-height: 25px;

          .carousel-text-link {
            text-decoration: none;
            font-size: 16px;
            color: $white;
          }

          .cta-container {
            .prButton-tertiary {
              color: $white;
              margin-top: 10px;
              cursor: pointer;
              .PRIcon {
                fill: $white;
              }
            }
          }
        }

        .carousel-text-date {
          background-color: $grey;
          padding: 3px 9px;
          font-size: 12px;
          line-height: 20px;
          font-weight: bold;
          display: inline-block;
        }

        .cta-container {
          display: flex;
        }
      }
    }
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: relative;
    opacity: 1;
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    display: flex;
  }

  .carousel-control-prev {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    justify-content: end;
  }

  .carousel-control-next {
    justify-content: start;
  }

  .carousel-indicators {
    li {
      background-color: transparent;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      border: 2px solid $white;

      &.active {
        background-color: $white;
      }
    }
  }

  .carousel-inner,
  .carousel-item-image {
    height: 726px;

    .lg & {
      height: 626px;
    }

    .md & {
      height: 526px;
    }

    .sm & {
      height: 426px;
    }

    .xs & {
      height: 171px;
    }
  }

  .carousel-item-image {
    width: 100%;
    background-size: cover;
    background-position: center;
  }
}
