@import "master";
#vehicle-menu {
  div[class^="MuiPaper-root"] {
    left: 0px !important;
    width: 100%;
    max-width: 100%;
    box-shadow: none !important;
    background-color: $lighter-grey;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .vehicle-menu-outer-container {
      width: 100%;
      display: flex;
      justify-content: center;

      .close-container {                 
        position: absolute;
        width: 40%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end; 
        .PRIcon {
          width: 20px;
          height: 23px;
          fill: $lighter-black;
          opacity: 0.5;
        }
      }
      .vehicle-menu-container {
        width: 100%;
        max-width: 1296px;
        margin-top: 18px;
        padding: 10px 0;
        margin-left: -40px;

        > div {
          // padding-left: 0px;
        }

        .vehicle-sub-category {
          color: $dark-grey;
          margin-bottom: 22px;

          .vehicle-sub-category-header {
            line-height: 25px;
            letter-spacing: 0.2px;
            padding: 3px;
            border-bottom: 1px solid $light-grey;
          }

          .vehicle-sub-category-content {
            margin: 10px;
            display: flex;

            flex-wrap:wrap;
            flex-grow: 6;
            .vehicle-sub-category-content-item {
              width: 150px;
              font-size: 12px;
              line-height: 20px;
              letter-spacing: 0.1px;
              display: flex;
              flex-direction: column;
              align-items: center;
              text-decoration: underline;

              img {
                width: 100%;
                height: 84px;
                image-rendering: -webkit-optimize-contrast;
              }
            }
          }
        }
      }
    }

    .vehicle-menu-footer-container {
      width: 100%;
      display: flex;
      justify-content: center;
      background-color: $lighter-grey;

      .vehicle-menu-footer {
        max-width: 1296px;
        width: 100%;
        margin: 28px 0 25px;

        .vehicle-menu-footer-link {
          padding: 3px 16px 9px 25px;
          border-right: 1px solid $light-grey;
          a {
            color: $dark-grey;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;

            &:hover,
            &:focus {
              text-decoration: underline;
              color: $grey;
            }
          }
          &:first-child {
            padding-left: 0px;
          }

          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}

.side-menu {
  [class*="MuiDrawer-paper"] {
    width: calc(100% - 20px);
    background-color: $light-grey;

    .side-menu-container {
      height: 100%;
      background-color: $light-grey;
      padding: 31px 59px 30px 16px;

      [class^="MuiPaper-root"] {
        box-shadow: none;
        background-color: transparent;

        &[class*="MuiExpansionPanel-expanded"] {
          margin: 0;

          &:before {
            opacity: 1;
          }
        }

        [class^="MuiButtonBase-root"] {
          padding: 0;
          min-height: 0;

          [class^="MuiExpansionPanelSummary-content"] {
            margin: 0;

            &[class*="MuiExpansionPanelSummary-expanded"] {
              .side-menu-item {
                color: $primary;
                padding-bottom: 9px;

                .PRIcon {
                  fill: $primary;
                }
              }
            }

            .side-menu-item {
              padding: 19px 0 16px;
              letter-spacing: 0.2px;
              line-height: 25px;
              color: $dark-grey;
              display: flex;
              align-items: center;

              a {
                font-size: 1rem;
                color: $dark-grey;
                text-decoration: none;
              }

              span {
                margin-right: 20px;
              }

              .PRIcon {
                fill: $dark-grey;
                margin-bottom: 5px;
              }
            }
          }
        }

        &:before {
          background-color: rgba($grey, 0.29);
        }

        [class*="MuiCollapse-container"] {
          [class*="MuiExpansionPanelDetails-root"] {
            padding: 0 16px;
            color: $dark-grey;
            display: flex;
            flex-direction: column;

            .vehicle-category {
              color: $dark-grey;
              margin-top: 12px;

              &:first-child {
                margin: 0;
              }

              .vehicle-category-title {
                font-weight: bold;
                line-height: 25px;
                letter-spacing: 0.2px;
              }

              .vehicle-category-items {
                padding: 7px 0 0 10px;
                line-height: 35px;
                letter-spacing: 0.2px;

                a {
                  color: $dark-grey;
                  font-size: 16px;
                  text-decoration: none;
                }
              }
            }

            .side-menu-subitems {
              .side-menu-subitem {
                line-height: 35px;

                a {
                  color: $dark-grey;
                  font-size: 16px;
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.menu-item {
  div[class^="MuiPaper-root"] {
    background-color: $lighter-grey;
    box-shadow: none !important;
    // width: 100%;
    min-width: 187px;
    max-width: 283px;

    .vehicle-menu-container {
      margin-top: 18px;
      padding: 32px;
      p {
        a {
          color: $medium-grey;
          font-size: 16px;
          letter-spacing: 0.1px;
          margin-bottom: 8px;
          text-decoration: none;
          cursor: pointer;

          &:last-child {
            margin-bottom: 0;
          }

          &:hover,
          &:focus {
            text-decoration: underline;
            color: $grey;
          }
        }
      }
    }
  }
}

.pr-menu {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  align-items: center;

  &.xs {
    justify-content: flex-start;
    flex-direction: row;

    .pr-menu-container {
      display: flex;
      align-items: center;
      padding: 6px 16px;
      justify-content: space-between;
      width: 100%;

      .PRIcon {
        width: 22px;
        fill: $darker-grey;
        &.hamburger-icon {
          width: 29px;
          height: 29px;
          margin-bottom: 6px;
        }
      }

      .logo-container {
        font-size: 12px;
        letter-spacing: 0.3px;
        font-weight: bold;
        color: $grey;
        display: flex;
        align-items: center;

        img {
          width: 40px;
          height: auto;
        }

        a {
          text-decoration: none;
          display: flex;
          align-items: center;
        }

        span {
          line-height: 20px;
          padding: 10px 0 10px 6px;
          margin-left: 6px;
          border-left: 1px solid $light-grey;
          font-size: 18px;
          text-decoration: none;
        }
      }

      .icon-container {
        display: flex;
        align-items: center;
        .PRIcon {
          width: 35px;
          height: 35px;

          &.search-icon {
            width: 29px;
            height: 29px;
            margin: 0 13px 6px 0;
          }
        }

        .bag-icon-container {
          position: relative;
          cursor: pointer;

          .cart-value-container {
            position: absolute;
            top: 12px;
            left: 0;
            font-size: 10px;
            letter-spacing: 0.1px;
            color: $primary;
            width: 100%;
            display: flex;
            justify-content: center;
          }
        }
      }
    }
  }

  .pr-menu-top-container {
    width: 100%;
    max-width: 1296px;

    .pr-menu-top-container-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 16px;
    }

    .other-item-container {
      display: flex;
      justify-content: flex-end;
      &.row {
        flex-wrap: nowrap;
        > div {
          width: 100%;
          display: flex;
          justify-content: flex-end;

          [id*="react"] {
            display: flex;
            align-items: center;
            padding: 0 25px;
            border-right: 1px solid rgba($light-grey, 0.5);

            &:last-child {
              border-right: 0px;
            }
          }
          &:last-child {
            border-right: 0px;
          }

          .scEmptyPlaceholder {
            width: 100%;
          }
        }
      }

      .PRIcon {
        width: 30px;
        fill: $lighter-black;
      }

      .other-item {
        display: flex;
        align-items: center;
        height: 100%;
        border-right: 1px solid $light-grey;
        position: relative;

        &:last-child {
          border-right: none;
        }

        .bag-icon-container {
          max-height: 30px;
          position: relative;
          cursor: pointer;
        }

        .cart-value-container {
          position: absolute;
          font-size: 10px;
          color: $primary;
          top: 10px;
          left: -20px;
          letter-spacing: 0.1px;
          padding: 0 35px;
          display: flex;
          justify-content: center;
          width: 100%;
        }

        .search-icon {
          width: 23px;
        }

        .search-field {
          margin-right: 6px;
          width: calc(100% - 23px);

          &:before {
            border-bottom-color: $light-grey;
          }
          ::placeholder {
            color: $dark-grey;
            line-height: 25px;
            font-size: 16px;
            letter-spacing: 0.2px;
          }
        }
      }
    }

    .logo-container {
      display: flex;
      align-items: center;
      font-size: 20px;
      letter-spacing: 0.4px;
      color: $grey;
      font-weight: bold;

      .pr-menu-logo {
        margin: 12px 20px 12px 0;
        padding-right: 22px;
        border-right: 1px solid $lighter-grey;

        img {
          width: 65px;
        }
      }
    }
  }

  .pr-menu-container-outer {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: $lighter-grey;

    .pr-menu-container {
      width: 100%;
      max-width: 1296px;

      > div {
        padding: 0 16px;
      }

      .pr-menu-item {
        margin: 18px 0;
        padding: 8px 24px;
        border-right: 1px solid $light-grey;
        display: inline-block;
        color: $dark-grey;
        line-height: 25px;
        cursor: pointer;

        a {
          font-size: 1rem;
          color: $dark-grey;
          text-decoration: none;
        }

        .PRIcon {
          fill: $dark-grey;
          margin-left: 12px;
          margin-top: 2px;
        }

        &:last-child {
          border-right: none;
        }

        &:first-child {
          padding-left: 0px;
        }

        &.active {
          color: $primary;

          .PRIcon {
            fill: $primary;
          }
        }

        &:hover,
        &:focus {
          color: $grey;
        }
      }
    }
  }
}