@import "master";
.pr-event-card {
    display: flex;
    padding: 20px 0 25px;
    border-bottom: 1px solid rgba($grey, 0.5);
    width: 100%;

    .xs & {
        padding: 31px 0;
    }

    &:last-child {
        border-bottom: none;
    }

    .pr-event-image-container {
        width: 42%;
        max-width: 304px;
        max-height: 205px;
        overflow: hidden;

        .xs & {
            display: none;
        }

        img {
            width: 100%;
        }
    }

    .pr-event-content-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 20px;
        width: 50%;

        .xs & {
            padding: 0;
            width: 100%;
        }

        .event-date {
            display: inline-block;
            padding: 3px 8px;
            background-color: $dark-grey;
            color: $white;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 0.1px;
            line-height: 20px;
            margin-bottom: 12px;
        }

        .event-title {
            margin-bottom: 12px;
        }

        .event-location {
            display: flex;
            align-items: center;

            .PRIcon {
                width: 22px;
                height: 25px;
                fill: $lighter-black;
                margin: 0 7px 0 -2px;
            }
        }

        .event-link {
            .xs & {
                margin-top: 18px;
            }
        }
    }
}