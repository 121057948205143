@import "master";
.delete-item-dialog {
  .dialog-paper {
    border-radius: 0;
    background: linear-gradient($lighter-grey, $light-grey);
    max-width: 628px;
    display: flex;
    padding: 32px 48px;
    .dialog-title {
      padding-bottom: 36px;
      h2 {
        font-size: 18px;
        letter-spacing: 0.2px;
        color: $darker-grey;
      }
    }
    .dialog-content-text {
      padding-bottom: 12px;
      font-family: "ToyotaText";
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0;
      color: $dark-grey;
      ul {
        margin: 0;
      }
    }
    .dialog-actions {
      align-self: center;
      .prButton:not(:last-child) {
        margin-right: 20px;
      }
    }
  }
}
