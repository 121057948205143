@import "master";
.pr-filter {
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba($darker-grey, 0.65), rgba($darker-grey, 0.7));
  width: 100%;

  &.sm {
    .pr-filter-container {
      padding-right: 16px;
      padding-left: 16px;

      .general-filter-container {
        flex-direction: column;

        .other-type-container {
          margin-top: 15px;

          .per-page {
            padding-left: 0;
          }
        }
      }

      .categories-filter-container {
        .categories {
          width: 25%;
        }
      }

      .specific-filter-container {
        .filter-row-container {
          .filter-row {
            width: 100%;
            flex-direction: column;
            .date-filter-column {
              width: 100%;
            }
            .query-filter-column {
              width: 100%;
              margin-left: 0;
              .pr-textfield {
                .textfield-root {
                  max-width: 400px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.xs {
    .pr-filter-container {
      padding: 16px 10px;
      .general-filter-container {
        .media-type-container {
          width: 100%;
          justify-content: space-between;

          .radio-container {
            margin: 0 10px;
            .radio-label {
              color: $white;
              font-size: 14px;
              line-height: 25px;
              text-align: center;
              padding-left: 0px;
              margin-bottom: 12px;
              letter-spacing: 0.1px;
              font-family: "ToyotaText";
              cursor: pointer;
            }

            .radio-button {
              display: none;

              &.checked + .radio-label {
                color: $darker-grey;
              }
            }
          }
        }
      }

      .categories-filter-container {
        flex-direction: column;
        .categories {
          width: 100%;
          .pr-dropdown {
            padding-right: 0;
          }
        }
      }

      h2 {
        padding-bottom: 12px;
        border-bottom: 1px solid rgba($grey, 0.5);
        margin-bottom: 12px;
      }

      .specific-filter-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .pr-dropdown {
          padding: 0;
          .dropdown-form-control {
            margin-left: 0;
            .input-underline {
              .dropdown-root {
                .dropdown-select {
                  text-align: left;
                }
              }
            }
          }
        }

        .toggle-filter {
          padding-top: 12px;
        }
      }
    }
  }

  .pr-filter-container {
    display: flex;
    max-width: 1062px;
    width: 100%;
    flex-direction: column;
    margin: 0 16px;
    padding: 28px 0;
    color: $white;

    .general-filter-container {
      display: flex;
      justify-content: space-between;
      color: $white;
      border-bottom: 1px solid rgba($light-grey, 0.2);

      .media-type-container {
        display: flex;
        align-items: center;

        .radio-container {
          margin-right: 28px;
          .radio-label {
            color: $white;
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.1px;
            font-family: "ToyotaText";
          }

          .radio-button {
            color: $white;
          }
        }
      }

      .other-type-container {
        display: flex;
        letter-spacing: 0.2px;

        > div {
          display: flex;
          border-right: 1px solid rgba($light-grey, 0.2);
          padding: 4px 22px 12px;
          align-items: center;

          &:last-child {
            border-right: none;
            padding-right: 0px;
          }
        }

        .pr-dropdown {
          .dropdown-form-control {
            margin-left: 7px;

            .input-root {
              .dropdown-root {
                .dropdown-select {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }

    .categories-filter-container {
      display: flex;
      color: $white;
      .categories {
        width: 20%;
        .pr-dropdown {
          padding: 0;
          padding-right: 20px;
          width: 100%;
          .dropdown-form-control {
            width: 100%;
          }
          .dropdown-label {
            font-size: 16px;
          }
        }
      }
    }

    .specific-filter-container {
      margin-top: 16px;
      letter-spacing: 0.2px;

      .toggle-filter {
        cursor: pointer;
        font-size: 12px;
      }

      .PRIcon {
        width: 15px;
        height: 23px;
        margin-left: 12px;
      }

      .filter-row-container {
        display: flex;
        justify-content: center;
        flex-direction: column;

        &.hide-filter {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.15s ease-out;
        }

        &.show-filter {
          max-height: 100%;
          transition: max-height 0.25s ease-in;
        }

        .filter-button-container {
          display: flex;
          margin-left: auto;

          .prButton {
            &:first-of-type {
              margin-right: 20px;
            }
            .PRIcon {
              height: 24px;
              width: 24px;
            }
          }
        }

        .filter-row {
          display: flex;
          width: 80%;
          margin-bottom: 30px;

          .date-filter-column {
            width: 60%;
            margin-right: 12px;

            .date-filter-row {
              display: flex;
              margin: 12px 0;
              align-items: center;

              .date-filter-label {
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 0.1px;
                margin-top: 12px;
              }
            }
          }

          .query-filter-column {
            width: 40%;
            margin-left: 12px;

            .pr-textfield {
              padding-left: 0;
            }
          }
        }
      }
    }

    h1 {
      margin-bottom: 20px;
    }
  }
}

.filter-dialog {
  .paper-root {
    width: 100%;
    margin: 0;
    height: 100%;
    border-radius: 0;
    max-height: 100%;
    background: $white;

    .dialog-container {
      height: 100%;
      background: linear-gradient(rgba($darker-grey, 0.65), rgba($darker-grey, 0.7));
      padding: 16px;

      .close-icon {
        .PRIcon {
          width: 16px;
          position: absolute;
          top: 20px;
          right: 16px;
        }
      }

      h2 {
        padding-bottom: 12px;
        border-bottom: 1px solid rgba($grey, 0.5);
      }

      .filter-row-container {
        .filter-row {
          margin: 30px 0;
          .query-filter-column {
            .pr-textfield {
              padding: 0;
              label {
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.2px;
              }
            }
          }

          .date-filter-column {
            .date-filter-row {
              .date-filter-label {
                color: $white;
                display: block;
              }

              .pr-date {
                margin-left: 0;
                display: inline-block;
                width: 50%;
                &:first-of-type {
                  padding-right: 10px;
                }
                .datepicker-root {
                  width: 100%;
                  max-width: unset;
                  .input-label-root {
                    font-size: 16px;
                    line-height: 25px;
                    letter-spacing: 0.2px;
                  }
                }
              }
            }
          }
        }

        .filter-button-container {
          display: inline-flex;
          flex-direction: column;

          .prButton {
            margin-bottom: 12px;
            span {
              width: calc(100% - 24px);
            }
          }
        }
      }
    }
  }
}
