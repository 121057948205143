@import "master";

.PRIcon {
    display: inline-block;
    line-height: 0px;
    position: relative;
    fill: $white;
    top: 2px;
    right: 0px;
     &.black svg{
      fill: $black;
    }
     &.grey svg {
      fill: $dark-grey;
    }
     &.white svg{
      fill: $white;
    }
  }
  
  .iconAnimateWrapper {
    display: inline-block;
    line-height: 0.5;
  }
  
  .arrowIcon {
    transition: fill 0.3s ease-in-out;
  }