@import "master";
.uploading {
  position: relative;

  .form-container {
    opacity: 0.1;
  }

  .uploading-container {
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    position: absolute;
  }
}

.form-container {
  .image-uploader-container {
    &.banner-container {
      .image-uploader {
        &.thumbnail {
          width: 100%;

          .image-dropzone {
            height: 252px;
            > div {
              height: 232px;
            }
          }
        }
      }
    }
  }

  .pr-date {
    margin-left: 18px;
    margin-top: 12px;
    .datepicker-root {
      .input-label-root {
        color: $darker-grey;
      }
      .input-root {
        .input-input {
          color: $darker-grey;
        }
        .calendar-icon {
          #calendar-icon-g {
            fill: $darker-grey;
          }
        }
      }
    }
  }

  .pr-textfield {
    .input-input {
      margin-right: 40px; // This is so the text doesn't overlap the error message
    }
  }
}
