@import "master";
.content-card {
    display: flex;

    img {
        width: 146px;
        height: 81px;
    }

    .content-card-content {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        .content-card-text {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.1px;
            color: $darker-grey;
        }

        .content-card-cta{
            display: flex;
            justify-content: flex-end;
        }
    }
}