@import "master";
.pr-pagination {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  background: linear-gradient(rgba($light-grey, 0.8), rgba($lighter-grey, 0.5));
  height: 109px;
  align-items: center;

  .pr-pagination-container {
    display: flex;
    width: 100%;
    max-width: 1062px;
    align-items: center;
    justify-content: space-between;
    margin: 0 16px;

    > div {
      display: flex;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0;
    }

    .page-number-container {
      .page-number {
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1px;
        color: $darker-grey;
        text-decoration: underline;

        .xs &,
        .sm & {
          font-size: 16px;
        }

        button {
          margin: 0 14px;
          background: none;
          border: none;
          padding: 0;
          cursor: pointer;

          &:hover,
          &:focus {
            outline: 0;
            text-decoration: none;
          }
        }

        &.current {
          button {
            font-weight: bold;
            text-decoration: none;
          }
        }

        &.disabled {
          text-decoration: none;
        }
      }
    }

    .PRIcon {
      width: 30px;
      height: 30px;
      fill: $primary;

      &.prev {
        transform: scaleX(-1);
      }
    }
  }
}
