@import "master";
@import "./../../../styles/_master.scss";

.PRMainLayout {
  border-top: solid 1px $light-grey;
//   margin-top: 55px;
}

.PRCardLayout {
  .fixedWidthCards {
    max-width: 707px;
    html.ie & {
      max-width: 100%;
    }
  }
}

.PR-section {
    display: flex;
    justify-content: center;

    &.colored {
        background: linear-gradient(rgba($light-grey, 0.6), rgba($lighter-grey, 0.4));
    }

    .PR-section-container {
        max-width: 1296px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 16px;
        >div {
          width: 100%;
      
          .scEmptyPlaceholder{
            width:100%;
          }

          >div {
            display: flex;
            justify-content: center;
          }
        }
    }

    .section-header {
        font-size: 18px;
        padding: 48px 0px 34px;
        line-height: normal;
        color: $darker-grey;
        width: 100%;
        max-width: 1062px;

        .xs & {
          padding: 20px 0;
        }

    }
}

/* Medium devices (desktops, laptops of size 992px and up) */

@media(max-width: $screen-xl) {
  .PRSplitLayout {
    >.container {
      max-width: 1062px;
    }
  }
}

@media(max-width: $screen-ss) {
  .PRMainLayout {
    margin-top: 0;
  }
}

.PRSplitLayout {
  display: flex;
  justify-content: center;

  >.container {
    max-width: 1062px;
    padding: 0;
    margin: 0 16px;

    .row {
      justify-content: space-between;
      margin: 0;
      .leftGutter {
        width: 70%;
        max-width: 731px;
        padding-right: 16px;

        .xs &, .sm & {
          width: 100%;
          max-width: 100%;
          padding-right: 0;
        }
      }
      .rightGutter {
        width: 30%;
        max-width: 303px;

        &.hide-on-modbile {
          .xs &, .sm & {
            display: none;
          }
        }
        
        .xs &, .sm & {
          width: 100%;
          max-width: 600px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}

.PRSplitLayout.layout-50 {
  >.container {
    .row {
      .leftGutter, .rightGutter {
        width: 50%;
        max-width: 530px;

        .xs & {
          width: 100%;
          max-width: 600px;
          padding: 0;
        }
      }

      .leftGutter {
        padding-right: 10px;

        .xs & {
          margin-bottom: 40px;
        }
      }

      .rightGutter {
        padding-left: 10px;
      }
    }
  }
}

//allows sticky side bar to work when in sitecore.
.sitecore {
  .PRSplitLayout {
    .leftGutter {
      >div>div,
      >div {
        height: 100%;
      }
    }
  }
}

.pr-row {
  display: flex;
  width: 100%;
  >div {
    display: flex;
    width: 100%;

    .scEmptyPlaceholder{
      width:100%;
    }
    .xs & {
      flex-direction: column;
    }
  }
}