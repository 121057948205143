@import "master";
.categories {
  display: flex;

  .pr-dropdown {
    .dropdown-form-control {
      margin-left: 0px;
      .dropdown-root {
        .dropdown-select {
          text-align: left;
        }
      }
    }
  }
}
