@import "master";
.pr-video-card {
    display: flex;
    justify-content: center;

    .pr-video-card-container {
        display: flex;
        width: 100%;
        max-width: 1062px;

        .pr-image-container {
            position: relative;

            img {
                width: 100%;
            }

            .card-video-icon {

                .PRIcon {
                    width: 106px;
                    position: absolute;
                    top: calc(50% - 53px);
                    left: calc(50% - 53px);
                    opacity: 0.7;
                }
            }
        }

        .pr-content-container {
            max-width: 302px;
            width: 100%;
            background: linear-gradient(rgba($light-grey, 0.7), rgba($lighter-grey, 0.5));
            padding: 40px 32px;
            color: $dark-grey;

            .title {
                font-size: 18px;
                color: $darker-grey;
                letter-spacing: 0.2px;
                font-weight: bold;
            }
        }
    }
}