@import "master";
.cart-item-container {
  margin-top: -8px;

  .popover-paper {
    box-shadow: none;
    background-color: transparent;
  }
}

.cart-container {
  min-width: 304px;
  margin-top: 8px;

  &::after {
    top: 0px;
    right: 33px;
    bottom: auto;
    left: auto;
    border-width: 0 8px 8px;
    border-color: $light-grey transparent;
    content: "";
    position: absolute;
    border-style: solid;
    display: block;
    width: 0;
  }

  .header {
    background-color: $light-grey;
    font-size: 12px;
    line-height: 20px;
    color: $darker-grey;
    font-weight: bold;
    letter-spacing: 0.1px;
    padding: 11px 19px;
    display: flex;
    justify-content: space-between;
  }

  .alice-carousel {
    padding: 0px;
    // width: 266px;

    .alice-carousel__wrapper {
      border: none;

      .alice-carousel__stage-item {
        // max-width: 333.328px;
        // margin: 0 32px;
        // min-width: 265px;
      }
    }
  }

  .items-container {
    padding: 19px;
    padding-top: 13px;
    background-color: $white;
    max-width: 304px;
    width: 100%;

    .carousel-control-container {
      display: flex;
      justify-content: center;
      padding: 21px 0 0;

      &.include-prev-next {
        justify-content: space-between;
      }

      .carousel-control {
        cursor: pointer;

        &.prev {
          .PRIcon {
            -moz-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }

        .PRIcon {
          width: 25px;
          height: 25px;
          fill: $primary;
        }

        &:hover {
          .PRIcon {
            fill: $dark-grey;
          }
        }
      }
    }

    .slide-container {
      display: flex;
      justify-content: center;
      flex-direction: column;

      .item-container {
        width: 100%;
        padding: 22px 0 14px;
        border-bottom: 1px solid rgba($grey, 0.5);
        color: $darker-grey;
        font-size: 12px;
        line-height: 15px;
        letter-spacing: 0.1px;
        display: flex;
        justify-content: space-between;

        .PRIcon {
          width: 20px;
          height: 20px;
          fill: $darker-grey;
          min-width: 20px;

          path {
            fill: $darker-grey;
          }

          &.close-icon {
            cursor: pointer;
            width: 15px;
            height: 15px;
            fill: $primary;
            min-width: 12px;
            margin-right: 3px;
          }
        }

        .item-content {
          padding: 0 16px 0 14px;
          width: 100%;
        }
      }
    }
  }
}
