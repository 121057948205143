@import "master";
.pr-dialog {
  .dialog-scroll-paper {
    border-radius: 0px;
    margin: 12px;

    .dialog-header {
      height: 128px;
      background-color: $light-grey;
      padding: 21px;
      .close-container {
        display: flex;
        justify-content: flex-end;
        .PRIcon {
          width: 20px;
          height: 23px;
          fill: $lighter-black;
          opacity: 0.5;
        }
      }

      .dynamic-header-content {
        display: flex;
        justify-content: center;
        margin-top: 12px;

        > div {
          max-width: 409px;
          width: 100%;
          display: flex;
          align-items: center;

          .PRIcon {
            width: 28px;
            height: 28px;
            fill: $darker-grey;
            margin-right: 16px;
            margin-bottom: 8px;
          }
        }
      }
    }

    .dialog-content {
      display: flex;
      justify-content: center;
      background: linear-gradient($lighter-grey, $light-grey);
      padding-bottom: 50px;

      .dialog-content-container {
        max-width: 442px;
        width: 100%;
        margin: 0 16px;
      }
    }
  }
  .dialog-scroll-paper-sm {
    max-width: 628px;
  }
  .dialog-scroll-paper-md {
    max-width: 1242px;
    .dialog-header {
      .dynamic-header-content > div {
        max-width: 1061px;
        width: 100%;
      }
    }
  }
}
