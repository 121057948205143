@import "master";
.events-list {
    .complex-tabs {
        .pr-tabs-content {
            .complex-tab {
                display: block;

                .pr-search {
                    .pr-search-cards {
                        max-width: 1062px;
                        margin: 0 16px;
                    }
                }
            }
        }
    }
}