@import "master";
.pr-article {
    .pr-article-date {
        font-size: 12px;
        font-weight: bold;
        color: $white;
        background-color: $grey;
        display: inline-block;
        padding: 3px 10px;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin-bottom: 30px;

        >span {
            margin-right: 5px;
            letter-spacing: 0.3px;
        }
    }

    .article-title {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.5px;
        color: $dark-grey;
    }

    .article-social-share {
        display: flex;
        align-items: flex-end;
        margin-bottom: 26px;

        span {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: $darker-grey;
            margin-right: 14px;
        }

        .PRIcon {
            width: 21px;
            height: 21px;
            fill: $grey;
            margin: 0 15px 3px 0;
            cursor: pointer;

            &:hover, &:focus {
                fill: $light-grey;
            }
        }
    }
}