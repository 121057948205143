@import "master";
.pr-banner {
  .banner-container {
    background-position: center;
    height: 587px;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    > div {
      width: 100%;

      &.banner-image {
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        img {
          max-width: 100%;
          width: 100%;
          max-height: auto;
          object-fit: cover;
          font-family: "object-fit: cover;";
          height: 100%;
        }
      }
    }

    &.no-bg-img {
      height: auto;

      .banner-image {
        display: none;
      }

      .lg &,
      .md &,
      .sm &,
      .xs & {
        height: auto;
      }
    }

    .lg & {
      height: 507px;
    }

    .md & {
      height: 427px;
    }

    .sm & {
      height: 327px;
    }

    .xs & {
      height: 214px;
    }
  }

  .banner-header-container {
    min-height: 103px;
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: rgba($darker-grey, 0.7);
    z-index: 2;

    .xs & {
      background-color: rgba($darker-grey, 0.6);
      min-height: auto;
    }

    .banner-header {
      max-width: 1062px;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 18px;
      color: $white;

      .xs & {
        margin: 16px;
      }

      &.has-subheader {
        h1 {
          margin-right: 35px;

          .xs & {
            margin: 18px;
          }
        }
      }

      h1 {
        .xs & {
          font-size: 24px;
          letter-spacing: 0.8px;
        }
      }

      .banner-subheader {
        max-width: 443px;
        padding-left: 35px;
        border-left: 1px solid rgba($light-grey, 0.2);

        .xs & {
          display: none;
        }
      }
    }
  }
}
