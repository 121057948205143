@import "master";
.item-list {
    margin-bottom: 24px;
    
    .item-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 11px;

        .item-content {
            display: flex;
            align-items: center;
            width: 100%;
            margin-right: 10px;

            .item-number {
                color: $dark-grey;
                padding: 6px 14px;
                background-color: $lighter-grey;
                font-weight: bold;
                margin-right: 10px;
                line-height: 25px; 
            }

            .item-title {
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.1px;
                color: $darker-grey;
            }
        }

        .item-action-item {
            width: 20px;
            height: 20px;

            .PRIcon {
                width: 11px;
                height: 11px;
            }
        }
    }
}