@import "master";
.PR-add-to-cart-icon {
    height: 40px;
    width: 40px;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;

    .PRIcon {
        &.plus {
            width: 18px;
            height: 18px;
            margin-bottom: 4px;
            fill: $lighter-black;
            cursor: pointer;
        }
    }
}