@import "master";
.preview-tip-row {
  display: flex;
  max-width: 1062px;
  width: 100%;
  margin: 0 16px;

  .xs & {
    margin: 0;
  }

  .preview-tip {
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 11px solid rgba($light-grey, 0.7);
    align-self: flex-start;
  }
}

.preview-row {
  width: 100vw;
  background: linear-gradient(rgba($light-grey, 0.7), rgba($lighter-grey, 0.5));
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;

  .prev-preview {
    margin-right: 16px;
    cursor: pointer;
    .PRIcon {
      width: 60px;
      height: 60px;
      fill: $grey;
      transform: scaleX(-1);
    }
    .xs & {
      display: none;
    }
  }

  .next-preview {
    margin-right: 16px;
    cursor: pointer;
    .PRIcon {
      width: 60px;
      height: 60px;
      fill: $grey;
    }
    .xs & {
      display: none;
    }
  }

  .preview-container {
    display: flex;
    max-width: 1062px;
    width: 100%;
    margin: 0 18px;
    padding: 48px 0;

    .image {
      background-size: cover;
      background-position: center;
      width: 629px;
      height: 350px;

      .xs & {
        display: none;
      }
    }

    .video {
      width: 629px;
      height: 350px;

      .xs & {
        display: none;
      }
    }

    .preview-content {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: start;

      .xs & {
        margin: 0;
      }

      .date {
        display: inline-block;
        background-color: $grey;
        font-size: 12px;
        line-height: 20px;
        font-weight: bold;
        padding: 4px 9px;
        color: $white;
        letter-spacing: 0.3px;
        margin-bottom: 16px;
      }

      h3 {
        margin-bottom: 12px;

        .xs & {
          font-weight: normal;
          font-size: 18px;
        }
      }

      p {
        font-size: 12px;
        line-height: 20px;
      }

      .pr-checkbox {
        margin-bottom: 14px;
      }

      .preview-actions {
        margin-top: auto;
        display: flex;
        align-items: flex-start;
        justify-content: end;

        .prButton:not(:last-child) {
          margin-right: 19px;
        }
      }
    }
  }
}
