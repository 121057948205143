@import "master";
.pr-media-card-list {
  max-width: 1062px;
  padding: 10px 0 44px;
  flex-direction: column;
  width: 100%;

  .xs & {
    padding: 0;
    padding-top: 10px;
  }

  .pr-carousel {
    &.xs {
      .pr-carousel-control {
        top: 55px;
      }
    }
  }

  &.row {
    max-width: 1062px;

    .pr-card-list-card {
      width: 20%;
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .pr-card-list-header-container {
    margin-bottom: 31px !important;
    border-bottom: rgba($grey, 0.5) solid 1px;
    padding-bottom: 11px;
    justify-content: space-between;
    display: flex;

    > div {
      display: flex;
    }

    .item-type {
      width: 24px;
      height: 24px;
      fill: $grey;
      margin: 2px 10px 0 0;
    }
  }

  .pr-card-list-cta {
    margin-top: 35px !important;
    justify-content: center;
  }
}
