@import "master";
.pr-image-copy {
    display: flex;
    justify-content: center;

    .pr-image-copy-container {
        display: flex;
        max-width: 1062px;
        padding: 60px 0;
        border-bottom: 1px solid rgba($grey, 0.5);
        margin: 0 16px;
        width: 100%;

        &.reverse {
            flex-direction: row-reverse;

            .pr-copy-container {
                margin: 0 0 0 10px;
            }

            .pr-image-container {
                margin: 0 10px 0 0;
            }
        }

        .xs & {
            flex-direction: column;
            margin: 0;
            padding: 30px 0 7px;
            border: none;
        }

        .pr-copy-container {
            width: 50%;
            margin-right: 10px;

            .xs & {
                margin: 0;
                padding: 0 0 25px;
                width: 100%;
            }

            .title {
                font-size: 18px;
                letter-spacing: 0.5px;
                color: $darker-grey;
                margin-bottom: 3px;
                font-weight: bold;
            }

            p {
                margin-bottom: 6px;
            }

            .link-container {
                display: flex;
                .PRIcon {
                    width: 22px;
                    fill: $black;
                    margin-right: 11px;
                }
            }
        }

        .pr-image-container {
            width: 50%;
            margin-left: 10px;

            .xs & {
                margin: 0;
                width: 100%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }
    }
}