@import "master";
.pr-date {
  margin-left: 11px;
  .datepicker-root {
    max-width: 164px;

    .input-label-root {
      color: $white;
      font-size: 12px;
      line-height: 20px;
    }

    .input-root {
      &::before {
        border-bottom: 2px solid rgba($grey, 0.8);
      }

      &::after {
        border-bottom: 2px solid $black;
      }

      .input-input {
        color: $white;
        font-size: 12px;
        line-height: 20px;
      }

      .input-adornment-root {
        button {
          background: none;
          height: 21px;
          width: 21px;
          margin-bottom: 16px;
          &:focus {
            outline: none;
          }
          .material-icons {
            overflow: visible;
            .calendar-icon {
              height: 21px;
              width: 21px;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
