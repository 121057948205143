// // Color system
// //
$primary: #d0021b;
$secondary: #ffffff;

$darker-grey: #4a4a4a;
$dark-grey: #666666;
$medium-grey: #737373;
$grey: #9b9b9b;
$light-grey: #d8d8d8;
$lighter-grey: #f5f6f7;

$dark-primary: #af0015;
$black: #000;
$lighter-black: #231f20;
$white: #fff;
$colors: ( 'dark-grey': $dark-grey, 'light-grey': $light-grey, 'black': $black, 'white': $white, 'dark-primary': $dark-primary);
$theme-colors: ( "primary": $primary, "secondary": $secondary, );
// // Spacing
// //
// // Control the default styling of most Bootstrap elements by modifying these
// // variables. Mostly focused on spacing.
// // You can add more entries to the $spacers map, should you need more variation.
$spacer: 16px;
$spacers: ( 
    0: 0, 
    1: ($spacer * .0625), // 1px
    2: ($spacer * .125), // 2px
    3: ($spacer * .25), // 4px
    4: ($spacer * .5), // 8px
    5: ($spacer), // 16px
    6: ($spacer * 1.5), // 24px
    7: ($spacer * 2), // 32px
    8: ($spacer * 2.5), // 40px
    9: ($spacer * 3), // 48px
    10: ($spacer * 3.5), // 56px
    11: ($spacer * 4), // 64px
    12: ($spacer * 5), // 80px
    13: ($spacer * 6), // 96px
    14: ($spacer * 10), // 160px
    15: ($spacer * 15) // 240px
);
//bootstrap original breakpoints
// $screen-xs: 320px;
// $screen-sm: 576px;
// $screen-md: 768px;
// $screen-lg: 992px;
// $screen-xl: 1200px;
//   $container-xs: 300px;
//   $container-sm: 540px;
//   $container-md: 720px;
//   $container-lg: 960px;
//   $container-xl: 1140px;
// TYO revised breakpoints
$screen-ss: 320px;
$screen-sm: 600px;
$screen-md: 960px;
$screen-lg: 1280px;
$screen-xl: 1920px;
$container-ss: 300px;
$container-sm: 600px;
$container-md: 960px;
$container-lg: 1280px;
$container-xl: 1920px;
// // Grid breakpoints
// //
// // Define the minimum dimensions at which your layout will change,
// // adapting to different screen sizes, for use in media queries.
$grid-breakpoints: ( 
xs: $screen-ss,
sm: $screen-sm,
md: $screen-md,
lg: $screen-lg
);

$grid-gutter-width: 20px;

// // Grid containers
// //
// // Define the maximum width of `.container` for different screen sizes.
$container-max-widths: ( ss: $container-ss, sm: $container-sm, md: $container-md, lg: $container-lg, xl: $container-xl);
// // Fonts
// //
// // Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif : "Open Sans" sans-serif;
$font-family-base : $font-family-sans-serif;
$headings-margin-bottom : ($spacer / 2);
// // Navbar
$navbar-padding-y : ($spacer / 2);
$navbar-padding-x : $spacer;

// Links
//
// Style anchor elements.
$link-colour:               $primary;
$link-decoration:           none !default;
$link-bg-color :            #5576a8;
$link-bg-hover-color :      #ddeeff;
$link-hover-decoration:     none;

//Buttons
$input-btn-focus-width: 1px;
$btn-focus-box-shadow: none;