@import "master";
.pr-media-list {
  .pr-media-card-container {
    display: flex;
    flex-wrap: wrap;

    .card-container {
      width: calc(50% - 6px);

      &:nth-of-type(odd) {
        margin: 0 6px 12px 0;
      }

      &:nth-of-type(even) {
        margin: 0 0 12px 6px;
      }
    }
  }
}
