@import "master";
.search-item {
    .search-field {
        min-width: 200px;
        position: relative;

        &.input-underline {
            &::before {
                left: 0;
                right: 0;
                bottom: 0;
                content: "\00a0";
                position: absolute;
                transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                border-bottom: 1px solid $light-grey;
                pointer-events: none;
            }
        }

        input {
            font: inherit;
            color: currentColor;
            border: 0;
            margin: 0;
            padding: 6px 0 7px;
            display: block;
            min-width: 0;
            flex-grow: 1;
            box-sizing: content-box;
            background: none;
            vertical-align: middle;
            -webkit-tap-highlight-color: transparent;
            outline: none;
        }

        &::after {
            border-bottom: $black;
        }
    }

    .search-icon {
        min-width: 23px;
        cursor: pointer;
    }
}