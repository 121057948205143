@import "master";
.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .form {
        width: 100%;

        .pr-checkbox {
            margin-top: 30px;
        }

        label {
            font-size: 12px;
            line-height: 20px;
            letter-spacing: 0.1px;
            color: $darker-grey;
        }

        .input-container {
            margin-left: 16px;
        }
    }

    .action-container {
        display: flex;
        margin-top: 40px;

        .submit-btn {
            margin-right: 20px;
        }
    }
}