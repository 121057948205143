@import "master";
.autocomplete {
  width: 100%;

  .paper-root {
    ul {
      list-style-type: none;
      padding-left: 0;

      li {
        .menu-item-root {
          font-family: "ToyotaText";

          > div {
            span {
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}
